import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Link } from "react-scroll";
import interpolate from "interpolate-range";

import sipka from "../../images/sipka.svg";
import SideImage from "../../components/Section/sideImage";
import BgImage from "../../components/Section/bgImage";
import ImageBig from "../../components/Section/imageBig";
import ImageSmall from "../../components/Section/imageSmall";
import Image from "../../components/Section/image";
import Centered from "../../components/Section/centered";
import CenteredSmall from "../../components/Section/centeredSmall";
import Html from "../../components/Section/html";
import Card from "../../components/Section/card";

const Cleaner = () => {
  return <div className="full-width" />;
};

const PartialContent = (props) => {
  const _page = props.graphCmsJob;
  const _blocks = [];
  _blocks.push(<Cleaner key={"partial_cleaner"} />);
  _page.partial.forEach((block) => {
    switch (block.blockType) {
      case "CARD_1":
        block.cleanerBefore &&
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
        _blocks.push(<Card key={block.id} {...block} id={block.id} flex={1} />);
        break;
      case "CARD_2":
        block.cleanerBefore &&
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
        _blocks.push(<Card key={block.id} {...block} id={block.id} flex={2} />);
        break;
      case "CARD_3":
        _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
        _blocks.push(<Card key={block.id} {...block} id={block.id} flex={3} />);
        _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
        break;
      case "HTML":
        _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
        _blocks.push(<Html key={block.id} {...block} id={block.id} />);
        _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
        break;
      case "IMAGE":
        _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
        _blocks.push(<Image key={block.id} {...block} id={block.id} />);
        _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
        break;
      case "IMAGE_BIG":
        _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
        _blocks.push(<ImageBig key={block.id} {...block} id={block.id} />);
        _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
        break;
      case "IMAGE_SMALL":
        _blocks.push(<ImageSmall key={block.id} {...block} id={block.id} />);
        break;
      case "CENTERED":
        _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
        _blocks.push(<Centered key={block.id} {...block} id={block.id} />);
        _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
        break;
      case "CENTERED_SMALL":
        _blocks.push(<CenteredSmall key={block.id} {...block} id={block.id} />);
        break;
      case "BGIMAGE":
        _blocks.push(<BgImage key={block.id} {...block} id={block.id} />);
        break;
      case "SIDEIMAGE":
      default:
        _blocks.push(<SideImage key={block.id} {...block} id={block.id} />);
        break;
    }
  });
  return _blocks;
};

const Job = ({ data }) => {
  const _page = data.graphCmsJob;
  const _illustr = _page?.illustr
    ? getImage(_page.illustr.localFile.childImageSharp)
    : null;
  const bgImage = convertToBgImage(_illustr);
  const paralaxRef = useRef();
  useEffect(() => {
    if (bgImage != null) {
      window.addEventListener("scroll", scrollHandler);
      scrollHandler();
      return () => window.removeEventListener("scroll", scrollHandler);
    }
  }, []);
  const scrollHandler = () => {
    const offsetMin = paralaxRef.current.selfRef.offsetTop;
    const offsetMax =
      paralaxRef.current.selfRef.offsetTop +
      paralaxRef.current.selfRef.offsetHeight;

    const config = {
      inputRange: [
        offsetMin - paralaxRef.current.selfRef.offsetHeight * 0.5,
        offsetMax + paralaxRef.current.selfRef.offsetHeight * 0.5,
      ],
      outputRange: [0, paralaxRef.current.selfRef.offsetHeight * 2],
      clamp: true,
    };
    const inter = interpolate(config);

    if (
      window.pageYOffset + window.innerHeight >= offsetMin &&
      window.pageYOffset < offsetMax
    )
      paralaxRef.current.selfRef.style.perspectiveOrigin =
        "center " +
        (inter(window.pageYOffset) - paralaxRef.current.selfRef.offsetHeight) +
        "px";
  };

  if (_page === null) return null;
  return (
    <div className="flex page">
      {bgImage && (
        <BackgroundImage
          Tag="section"
          {...bgImage}
          preserveStackingContext
          className="lead-illustr paralax"
          ref={paralaxRef}
        >
          <h1>{_page.title}</h1>
          <div className="flex">
            <p>{_page.place} | </p>
            <p>{_page.kind} | </p>
            <p>{_page.salary}</p>
          </div>

          <Link
            to="mainContent"
            smooth={true}
            duration={500}
            className="link"
            href={"#mainContent"}
          >
            <img className="sipka" src={sipka} />
          </Link>
        </BackgroundImage>
      )}
      {bgImage === null && (
        <div className="lead-text centered">
          <h3>{_page.leadline}</h3>
          <h1>{_page.title}</h1>
          <p>{_page.description}</p>

          <Link
            to="mainContent"
            smooth={true}
            duration={500}
            className="link"
            href={"#mainContent"}
          >
            <img className="sipka" src={sipka} />
          </Link>
        </div>
      )}
      <div id="mainContent"></div>
      {_page.section1 && (
        <SectionWrapper className="container">
          <div dangerouslySetInnerHTML={{ __html: _page.section1.html }}></div>
        </SectionWrapper>
      )}
      {_page.section2 && (
        <SectionWrapper className="container">
          <div dangerouslySetInnerHTML={{ __html: _page.section2.html }}></div>
        </SectionWrapper>
      )}
      {_page.section3 && (
        <SectionWrapper className="container">
          <div dangerouslySetInnerHTML={{ __html: _page.section3.html }}></div>
        </SectionWrapper>
      )}
      <PartialContent {...data} />
    </div>
  );
};

export const query = graphql`
  query($slug: String) {
    graphCmsJob(slug: { eq: $slug }, locale: { eq: en }) {
      id
      section1 {
        html
      }
      section2 {
        html
      }
      section3 {
        html
      }
      illustr {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
      title
      category
      description
      salary
      place
      kind
      icon {
        gatsbyImageData
      }
      partial {
        ... on GraphCMS_Page {
          id
          category
          slug
          title
          illustr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          blockType
          description
          icon {
            localFile {
              publicURL
            }
          }
          colors {
            hex
          }
          buttonText
          leadLine
          locale
        }
        ... on GraphCMS_Job {
          id
          category
          colors {
            hex
          }
          slug
          illustr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title
          blockType
          buttonText
          description
          salary
          place
          kind
          locale
        }
        ... on GraphCMS_Block {
          id
          blockType
          buttonText
          category
          colors {
            hex
          }
          description
          download
          illustr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          link
          leadLine
          slug
          title
          locale
          markup {
            html
          }
          icon {
            localFile {
              publicURL
            }
          }
          cleanerBefore
        }
      }
    }
  }
`;
const SectionWrapper = styled.section`
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;

    p {
      display: inline-flex;
      padding: 4px;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      border: 4px solid #eb2252;
      margin-right: 10px;
      font-size: 30px;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: #eb2252;
      font-weight: bold;
      text-align: center;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 25px;
  }
`;

export default Job;
